import React, { useState } from 'react';
import { Box, FormControl, InputLabel, TextField, Button, Typography, MenuItem, Select, InputAdornment } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';

const LoanApplicationForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    mobile: '',
    aadhar: '', // Change to store file names or URLs
    pan: '',    // Change to store file names or URLs
    loanCategory: '',
    document: '',
    loanAmount: '',
  });

  const loanCategories = [
    { value: 'Personal Loan', label: 'Personal Loan' },
    { value: 'Home Loan', label: 'Home Loan' },
    { value: 'Car Loan', label: 'Car Loan' },
    { value: 'Education Loan', label: 'Education Loan' },
  ];

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle file changes
  const handleFileChange = (e, field) => {
    const file = e.target.files[0];
    if (file) {
      const fileName = file.name;
      setFormData({
        ...formData,
        [field]: fileName,
      });
      // Optionally, you can handle file upload logic here
      // e.g., upload to server or cloud storage
      alert(`${field} file selected: ${fileName}`);
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    console.log(formData);
    // Submit form logic
    alert(`Submitting loan application with data: ${JSON.stringify(formData)}`);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '90vh',
        padding: '0px 20px',
        // backgroundColor: '#f5f5f5',
      }}
    >
      <Box
        sx={{
          maxWidth: 500,
          width: '100%',
          padding: 4,
          borderRadius: 2,
          backgroundColor: 'white',
          boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
        }}
      >
        <Typography variant="h5" textAlign="center" mb={4}>
          Loan Application
        </Typography>

        <TextField
          fullWidth
          label="Full Name"
          name="fullName"
          value={formData.fullName}
          onChange={handleInputChange}
          sx={{ mb: 2, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
        />

        <TextField
          fullWidth
          label="Mobile Number"
          name="mobile"
          value={formData.mobile}
          onChange={handleInputChange}
          sx={{ mb: 2, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            fullWidth
            label="Aadhar Card"
            name="aadhar"
            value={formData.aadhar}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    sx={{ marginLeft: 1 }}
                  >
                    Upload
                    <input type="file" hidden onChange={(e) => handleFileChange(e, 'aadhar')} />
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{ mr: 1, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            fullWidth
            label="PAN Card"
            name="pan"
            value={formData.pan}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    sx={{ marginLeft: 1 }}
                  >
                    Upload
                    <input type="file" hidden onChange={(e) => handleFileChange(e, 'pan')} />
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{ mr: 1, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
          />
        </Box>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel htmlFor="loanCategory">Loan Category</InputLabel>
          <Select
            id="loanCategory"
            name="loanCategory"
            value={formData.loanCategory}
            onChange={handleInputChange}
            sx={{ borderRadius: '8px' }}
          >
            {loanCategories.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {category.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            fullWidth
            label="Upload Document/Picture"
            name="document"
            value={formData.document}
            onChange={handleInputChange}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    color="primary"
                    component="label"
                    sx={{ marginLeft: 1 }}
                  >
                    Upload
                    <input type="file" hidden onChange={(e) => handleFileChange(e, 'document')} />
                  </Button>
                </InputAdornment>
              ),
            }}
            sx={{ mr: 1, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
          />
        </Box>

        <TextField
          fullWidth
          label="Required Loan Amount"
          name="loanAmount"
          value={formData.loanAmount}
          onChange={handleInputChange}
          sx={{ mb: 4, borderRadius: '8px', '& .MuiOutlinedInput-root': { borderRadius: '8px' } }}
        />

        <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default LoanApplicationForm;
