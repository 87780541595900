import React, { useState } from 'react';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Container, Typography } from '@mui/material';
import { MdDeleteForever } from "react-icons/md";
const LoanCategory = () => {
    // State to handle form data
    const [loanCategory, setLoanCategory] = useState("");

    const data = [
        { id: 1, name: 'Personal Loan', description: 'Loan for personal expenses' },
        { id: 2, name: 'Home Loan', description: 'Loan for purchasing a home' },
        { id: 3, name: 'Car Loan', description: 'Loan for buying a car' },
        { id: 4, name: 'Education Loan', description: 'Loan for education purposes' },
    ];

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Loan data submitted:', loanCategory);
        // Add logic to handle saving the loan data
    };

    return (
        <Container maxWidth="lg">
            <Paper style={{ padding: 16, marginBottom: 16 }}>
                <Typography variant="h4" gutterBottom>
                    Create Loan Application
                </Typography>

                {/* Form starts here */}
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Loan Category"
                        name="loanCategory"
                        value={loanCategory}
                        onChange={(e) => setLoanCategory(e.target.value)}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                    />

                    {/* Save button */}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"

                        style={{ marginTop: 16 }}
                    >
                        Save
                    </Button>
                </form>
            </Paper>

            <Paper style={{ padding: 16 }}>
                <Typography variant="h5" gutterBottom>Manage Financers</Typography>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>S.No</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.name}</TableCell>

                                <TableCell>
                                    <IconButton
                                        onClick={() => console.log(row.name)}
                                        aria-label="Delete">
                                        <MdDeleteForever />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Container>
    );
};

export default LoanCategory;
