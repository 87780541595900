// In your App.js or a similar file
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./pages/Login";
import AdminDashboard from "./pages/AdminDashnoard";
import ManageFinanceList from "./Components/Core/AdminDashboard/ManageFinancerList";
import LoanAppliactionsList from "./Components/Core/AdminDashboard/LoanApplication";
import LoanCategory from "./Components/Core/AdminDashboard/LoanCategory";
import LoanApplicationForm from "./Components/Core/AdminDashboard/LoanApplicationForm";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/Admin/Dashboard" element={<AdminDashboard />}>
          <Route path="/Admin/Dashboard/manage-financers" element={<ManageFinanceList />}> </Route>
          <Route path="/Admin/Dashboard/manage-loan-application" element={<LoanAppliactionsList/> }></Route>
          <Route path="/Admin/Dashboard//manage-loan-category" element={<LoanCategory />}> </Route>
          <Route path="/Admin/Dashboard/create-loan-application" element={<LoanApplicationForm/> }> </Route>

        </Route>
      </Routes>
    </Router>
  );
}

export default App;
