import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Paper, Container, Typography } from '@mui/material';
// import { Visibility as VisibilityIcon } from '@mui/icons-material';

const ManageFinanceList = () => {
    // Sample data for the table
    const data = [
        { id: 1, name: 'John', surname: 'Doe', email: 'john.doe@example.com' },
        { id: 2, name: 'Jane', surname: 'Smith', email: 'jane.smith@example.com' },
        // Add more data as needed
    ];

  
    return (
        <Container>
            <Paper style={{ padding: 16 }}>
                <Typography variant="h5" gutterBottom>Manage Financers</Typography>
              
               <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>S.No</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Surname</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>View</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.surname}</TableCell>
                                <TableCell>{row.email}</TableCell>
                                <TableCell>
                                    <IconButton aria-label="view">
                                        {/* <VisibilityIcon /> */}
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            </Paper>
        </Container>
    );
};

export default ManageFinanceList;
