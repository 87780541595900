import React from 'react';
import { Table, TableHead, TableBody, TableRow, Container, Paper, TableCell, IconButton, Typography, TableContainer } from '@mui/material';
import { FaPlus, FaEye } from 'react-icons/fa'; // Importing icons from react-icons
import { useNavigate } from 'react-router-dom'; // Import useNavigate for routing

const data = [
    { name: 'John Doe', mobile: '123-456-7890', loanCategory: 'Personal Loan', status: 'Approved' },
    { name: 'Jane Smith', mobile: '987-654-3210', loanCategory: 'Home Loan', status: 'Pending' },
    // Add more data as needed
];

const LoanApplicationsList = () => {
    const navigate = useNavigate(); // Initialize useNavigate hook

    const handleAddFinancer = () => {
        navigate('/admin/dashboard/create-loan-application'); // Navigate to add-financer page when button is clicked
    };

    return (
        <Container>
            <Paper style={{ padding: 16, position: 'relative' }}>
                <Typography variant="h5" gutterBottom>Manage Loan Applications</Typography>

                {/* IconButton placed at the top right side */}
                <div
                onClick={handleAddFinancer}
                style={{
                    position: 'absolute',
                    top: 10,
                    cursor:"pointer",
                    right: 16,
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#1976d2', // Blue background (Material UI's default primary color)
                    padding: '8px 16px',
                    borderRadius: '8px',
                    color: 'white' // White text color
                }}>
                    <IconButton
                        aria-label="add"
                        style={{ color: 'white' }} // White icon color
                       
                    >
                        <FaPlus />
                    </IconButton>
                    <Typography variant="body1" style={{ marginLeft: 8, color: 'white' }}>Add Loan Application</Typography>
                </div>


               
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell><Typography variant="">Name</Typography></TableCell>
                                <TableCell><Typography variant="">Mobile</Typography></TableCell>
                                <TableCell><Typography variant="">Loan Category</Typography></TableCell>
                                <TableCell><Typography variant="">Status</Typography></TableCell>
                                <TableCell><Typography variant="">View</Typography></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.mobile}</TableCell>
                                    <TableCell>{row.loanCategory}</TableCell>
                                    <TableCell>{row.status}</TableCell>
                                    <TableCell>
                                        <IconButton color="primary">
                                            <FaEye /> {/* React Icon for viewing details */}
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
              
            </Paper>
        </Container>
    );
};

export default LoanApplicationsList;
