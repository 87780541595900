import React from 'react';
import { Box, List, ListItem, ListItemText, Typography, Drawer, AppBar, Toolbar, Button } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';
// Sidebar Links
const sidebarLinks = [
    { text: 'Manage Financers', path: '/Admin/Dashboard/manage-financers' },
    { text: 'Manage Loan Application', path: '/Admin/Dashboard/manage-loan-application' },
    { text: 'Manage Loan Category', path: '/Admin/Dashboard/manage-loan-category' },
    
];

const Sidebar = ({ open, onClose }) => (
    <Drawer
        open={open}
        onClose={onClose}
        variant="persistent" // Changed to 'persistent' for better control
        sx={{
            padding: '10px',
            maxWidth: 240,
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                maxWidth: 240,
                boxSizing: 'border-box',
                backgroundColor: '#fafafa', // Custom background color for the sidebar
            },
        }}
    >
        <Box sx={{ overflow: 'auto' }}>
            <List>
                <ListItem sx={{ display: 'flex', justifyContent: 'space-between',backgroundColor:"primary" }}>
                    <Typography variant="h6"  component="div" >
                        Admin Panel
                    </Typography>
                    {/* <Button onClick={onClose}>Close</Button> */}
                </ListItem>
                {sidebarLinks.map((item, index) => (
                    <ListItem
                        button
                        key={index}
                        sx={{
                            '&:hover': {
                                backgroundColor: '#e0e0e0', // Custom hover color
                            },
                            backgroundColor: '#ffffff', // Custom color
                            borderRadius: 1,
                            mb: 1,
                        }}
                    >
                        <Link to={`${item.path}`}>
                            <ListItemText
                                primary={item.text}
                                sx={{ color: '#333' }} // Custom text color
                            /></Link>
                    </ListItem>
                ))}
            </List>
        </Box>
    </Drawer>
);

const DashboardContent = () => (
    <Box
        component="main"
        sx={{
            flexGrow: 1,
            bgcolor: 'background.default',
            p: 3,
            ml: { xs: 0, sm: '240px' }, // Responsive margin-left
            mt: '64px', // AppBar height
        }}
    >
        <Typography variant="h4" gutterBottom>
            Admin Dashboard
        </Typography>
        {/* Replace this section with dynamic content based on selected sidebar link */}
        <Typography paragraph>
            Welcome to the admin dashboard. Please select an option from the sidebar.
        </Typography>
    </Box>
);

const AdminDashboard = () => {
    const [open, setOpen] = React.useState(true); // Default open to true

    const handleDrawerToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            {/* AppBar (Top Navbar) */}
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${open ? '215px' : '0px'})`, // Adjust width based on Sidebar
                    ml: `${open ? '' : '0px'}`, // Adjust margin-left based on sidebar open state
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        Admin Panel
                    </Typography>
                    <Button onClick={handleDrawerToggle} sx={{ ml: 'auto' }}>
                        {open ? 'Close Sidebar' : 'Open Sidebar'}
                    </Button>
                </Toolbar>
            </AppBar>

            {/* Main Content Area */}
            <Box sx={{ display: 'flex', height: '100%' }}>
                <Sidebar open={open} onClose={() => setOpen(false)} />
                <Box component="main"
                    sx={{
                        flexGrow: 1,
                        bgcolor: 'background.default',
                        p: 3,
                        ml: { xs: 0, sm: '196px' }, // Responsive margin-left
                        mt: '64px', // AppBar height
                    }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
};

export default AdminDashboard;
